<template>
  <k-field-group language-prefix="person.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row>
          <v-col
            cols="12"
          >
            <v-row no-gutters>
              <KTextField
                v-model="values.firstName"
                field="firstName"
                grid="col-sm-4 pr-2"
                required
              />
              <KTextField
                v-model="values.insertion"
                field="insertion"
                grid="col-sm-3 pr-2"
              />
              <KTextField
                v-model="values.lastName"
                field="lastName"
                grid="col-sm-5"
                required
              />
            </v-row>
            <Contact
              v-model="values.contactDetail"
              :fields="contactDetailFields"
            />
            <KFileField
              v-model="values.profilePicture"
              field="profilePicture"
              accept="image/*"
            />
            <v-row no-gutters>
              <KTextField
                v-model="values.position"
                field="position"
                required
              />
              <LocationDepartmentTreeField
                :employer-id="employer.id"
                :value="locationsAndDepartments"
                field="locationAndDepartments"
                @input="handleLocationDepartmentChange"
              />
            </v-row>
          </v-col>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import Contact from '@/modules/contactDetail/components/ContactForm.vue';
import LocationDepartmentTreeField from '@/modules/location/components/LocationDepartmentTreeField.vue';

export default {
  name: 'PersonForm',
  components: {
    LocationDepartmentTreeField,
    Contact,
    KTextField,
    KFieldGroup,
    KFormDialog,
    KFileField,
  },
  props: {
    values: {
      type: Object,
    },
    employer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contactDetailFields() {
      return [{
        name: 'email',
        settings: { required: true },
      }, {
        name: 'phone',
        settings: { required: true },
      },
      {
        name: 'mobilePhone',
      }];
    },
    locationsAndDepartments() {
      return {
        locations: this.values.locations,
        departments: this.values.departments,
      };
    },
    panels() {
      return [
        { name: this.$t('person.tabs.default') },
      ];
    },
  },
  methods: {
    handleLocationDepartmentChange(locationDepartment) {
      this.values.locations = locationDepartment.locations;
      this.values.departments = locationDepartment.departments;
    },
  },
};
</script>

<template>
  <v-autocomplete
    v-model="selected"
    v-bind="fieldProps"
    :items="items"
    :label="translatedLabel"
    :rules="computedRules"
    multiple
    v-on="$listeners"
    @change="handleChange"
  >
    <template #selection="{ item }">
      <v-chip v-if="item.departmentId">
        {{ item.text }}
      </v-chip>
      <v-chip v-else>
        Alles van: {{ item.text }}
      </v-chip>
    </template>
    <template #item="{ item, on }">
      <template v-if="!item.departmentId">
        <v-list-item v-on="on">
          <v-icon
            v-if="isActive(item.value)"
            class="mr-2"
          >
            $checkboxOn
          </v-icon>
          <v-icon
            v-else-if="hasActiveChildren(item.value)"
            class="mr-2"
          >
            $checkboxIndeterminate
          </v-icon>
          <v-icon
            v-else
            class="mr-2"
          >
            $checkboxOff
          </v-icon>
          {{ item.text }}
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item v-on="on">
          <v-icon
            v-if="isActive(item.value) || hasActiveParent(item.value)"
            class="mr-2 ml-6"
          >
            $checkboxOn
          </v-icon>
          <v-icon
            v-else
            class="mr-2 ml-6"
          >
            $checkboxOff
          </v-icon>
          {{ item.text }}
        </v-list-item>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { locationTree } from '@/modules/employer/api/employer.js';
import Field from '@/components/crud/fields/FieldMixin.vue';

export default {
  name: 'LocationDepartmentTreeField',
  mixins: [Field],
  props: {
    value: {
      type: Object,
      default: () => ({
        locations: [],
        departments: [],
      }),
    },
    employerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      selected: [],
    };
  },
  watch: {
    employerId() {
      this.getItems();
    },
    value() {
      this.convertArraysInSelected(this.value);
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      this.items = [];
      const response = await locationTree(this.employerId);
      response.data.data.forEach(location => {
        this.items.push({
          text: location.name,
          locationId: location.id,
          value: `${location.id}.all`,
        });
        location.departments.forEach(department => {
          this.items.push({
            text: department.name,
            departmentId: department.id,
            locationId: location.id,
            value: `${location.id}.${department.id}`,
          });
        });
      });

      this.convertArraysInSelected(this.value);
    },
    isActive(valueString) {
      return this.selected.find(value => value === valueString);
    },
    hasActiveChildren(valueString) {
      const splitter = valueString.split('.');
      const locationId = splitter[0];

      return this.selected.find((childString) => {
        const childSplitter = childString.split('.');
        const childLocationId = childSplitter[0];

        return childLocationId === locationId;
      });
    },
    hasActiveParent(valueString) {
      const splitter = valueString.split('.');
      const locationId = splitter[0];
      return this.selected.find(parentString => parentString === `${locationId}.all`);
    },
    handleChange() {
      this.selected.forEach((valueString, index) => {
        const splitter = valueString.split('.');
        const locationId = splitter[0];
        const departmentId = splitter[1];

        if (departmentId === 'all') {
          return;
        }

        if (this.selected.find(childString => childString === `${locationId}.all`)) {
          this.selected.splice(index, 1);
        }
      });
      this.$emit('input', this.convertSelectedInTwoArrays(this.selected));
    },
    getLocationByDepartment(department) {
      return this.items.find(item => item.departmentId === parseInt(department)).locationId;
    },
    convertSelectedInTwoArrays(array) {
      const locations = [];
      const departments = [];

      array.forEach(valueString => {
        const splitter = valueString.split('.');
        const locationId = splitter[0];
        const departmentId = splitter[1];

        if (departmentId === 'all') {
          locations.push(locationId);
          return;
        }
        departments.push(departmentId);
      });
      return {
        locations,
        departments,
      };
    },
    convertArraysInSelected(values) {
      const {
        locations,
        departments,
      } = values;
      this.selected = [];

      if (locations) {
        locations.forEach(location => {
          this.selected.push(`${location}.all`);
        });
      }
      if (departments) {
        departments.forEach(department => {
          const location = this.getLocationByDepartment(department);
          this.selected.push(`${location}.${department}`);
        });
      }
    },
  },
};
</script>

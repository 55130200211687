<template>
  <div id="page-users-list">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('person.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('person.title', 1) }) }}
        </v-btn>
        <PersonForm
          v-model="createDialog"
          :employer="employer"
          :request="createRequest"
          :title="$tc('person.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="person.fields"
          resource="person"
          show-expand
          single-expand
          @click:edit="openUpdate"
          @click:row="$router.push({ name: 'employer.person', params: { personId: $event.id, employerName: employer.name } })"
        >
          <template #expanded-item="{ item }">
            <v-row class="ma-0">
              <v-col cols="4">
                <KLinkDisplay
                  link-type="phone"
                  icon="$phone"
                  :href="item.phone"
                />
                <KLinkDisplay
                  link-type="email"
                  icon="$email"
                  :href="item.email"
                />
                <k-text-display
                  v-for="role in item.access.roles"
                  :key="role.id"
                  icon="$role"
                >
                  {{ role.name }}
                </k-text-display>
              </v-col>
              <v-col cols="8">
                <k-text-display
                  v-for="location in item.locations"
                  :key="location.id"
                  icon="$location"
                >
                  {{ location.name }} > {{ $t('global.all') }}
                </k-text-display>
                <k-text-display
                  v-for="department in item.departments"
                  :key="department.id"
                  icon="$department"
                >
                  {{ department.locationName }} > {{ department.name }}
                </k-text-display>
              </v-col>
            </v-row>
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
    <PersonForm
      v-model="updateDialog"
      :employer="employer"
      :request="updateRequest"
      :title="$tc('person.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Person from '@/application/models/Person';
import KLinkDisplay from '@/components/crud/display/KLinkDisplay.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/person/api/person';
import PersonForm from '@/modules/person/components/PersonForm.vue';

export default {
  name: 'PersonIndex',
  components: {
    KTextDisplay,
    KLinkDisplay,
    PersonForm,
    KCrudTable,
    KCrudLayout,
  },
  props: {
    employer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Person(),
      createFormValues: new Person(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'phone',
          language: 'contactDetail.phone',
        },
        {
          value: 'email',
          language: 'contactDetail.email',
        },
        {
          value: 'position',
          language: 'position',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.persons' },
        text: this.$tc('person.title', 2),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new Person();
      this.createDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.$route.params.employerId, this.createFormValues);
    },
    indexRequest() {
      return index(this.$route.params.employerId, ...arguments);
    },
    async openUpdate(item) {
      this.updateFormValues = new Person();
      const personShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(personShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>

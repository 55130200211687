import Contact from '@/application/models/Contact';
import Model from '@/application/models/model.js';
import mapFileForRequest from '@/application/util/mapFileForRequest';

class Location extends Model {
  id = 0;
  firstName = '';
  insertion = '';
  lastName = '';
  contactDetail = new Contact();
  locations = [];
  departments = [];
  hasAccess = false;
  profilePicture = null;
  position = '';
  access = {
    email: '',
    roles: [],
    locations: [],
    departments: [],
  };

  mapForRequest() {
    return {
      firstName: this.firstName,
      insertion: this.insertion,
      lastName: this.lastName,
      contactDetail: this.contactDetail.mapForRequest(),
      hasAccess: this.hasAccess,
      locations: this.locations,
      departments: this.departments,
      position: this.position,
      profilePicture: mapFileForRequest(this.profilePicture),
      access: this.hasAccess ? {
        email: this.access.email,
        roles: this.access.roles.map((role) => role.id),
        locations: this.access.locations,
        departments: this.access.departments,
      } : undefined,
    };
  }

  mapResponse(data) {
    return this.initializeObjectFromApi({
      ...data,
      locations: data.locations.map(location => location.id),
      departments: data.departments.map(department => department.id),
      access: data.access ? {
        ...data.access,
        locations: data.access.locations.map(location => location.id),
        departments: data.access.departments.map(department => department.id),
      } : null,
    }, this);
  }
}

export default Location;
